import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby'
import ChevronDown from "../../assets/images/icons/chevron-down-solid.svg"
import logo from "../../assets/images/logo.png"
import { navigate } from "gatsby"



const Navbar = (props) => {

    const [menuActive, setMenuActive] = useState(false)

    const [mobileActive, setMobileActive] = useState("")

    const [selectedOption, setSelectedOption] = useState( props.lang === "EN" ? "EN" : ( props.lang === "FR" ? "FR" : "EL"))

    const data = props.header;

    useEffect(() => {
        let elementId = document.getElementById("navbar");

        if (!isMobileDevice()) {
            document.getElementById("nav-logo").style.width = "120px";
        } else {
            document.getElementById("nav-logo").style.width = "75px";
        }

        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
                document.getElementById("nav-logo").style.width = "75px";
            } else {
                elementId.classList.remove("is-sticky");
                if (!isMobileDevice()) {
                    document.getElementById("nav-logo").style.width = "120px";
                } else {
                    document.getElementById("nav-logo").style.width = "75px";
                }
            }
        });
        window.scrollTo(0, 0);
    }, [])

    function isMobileDevice() {
        var check = false;
        (function (a) { if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(a.substr(0, 4))) check = true; })(navigator.userAgent || navigator.vendor || window.opera);
        return check;
    };

    function openMobileMenu(e, i) {
        e.preventDefault()
        if (i !== mobileActive && window.innerWidth < 992) {
            setMobileActive(i)
        }
    }

    function toggleMenu() {
        setMenuActive(!menuActive)
        setMobileActive("")
    }

    function changeLang(lang){
        setSelectedOption(lang);
        
        if( lang === "EL"){
            navigate(`/`);
        }else{
            navigate(`/${lang.toLowerCase()}/`);
        }
    }

    // const classOne = collapsed ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    // const classTwo = collapsed ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <React.Fragment>
            <div id="navbar" className="navbar-area" style={props.isRelative ? { position: "relative" } : { top: "0" }}>
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light" style={{ minHeight: "55.11px" }}>
                            <Link
                                to={props.lang === "EN" ? "/en/" : ( props.lang === "FR" ? "/fr/" : "/")}
                                className="navbar-brand"
                            >
                                <img id="nav-logo" src={logo} className={"w3-logo"} alt="logo" />
                            </Link>

                            <button
                                className={!menuActive ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right'}
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={() => toggleMenu()}
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={!menuActive ? `collapse navbar-collapse` : `collapse navbar-collapse show`} id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    {console.log(data.menuItems.edges)}
                                    {data.menuItems && data.menuItems.edges.map((page, i) => {
                                        if (!page.node.parentId) {
                                            return (
                                                <li className="nav-item" key={`nav-link-${i}`}>
                                                    <Link
                                                        to={`/${page.node.url.replace("http://", "")}/`}
                                                        activeClassName="active"
                                                        // onClick={e => e.preventDefault()}
                                                        className="nav-link"
                                                    >
                                                        {page.node.label}
                                                        {(page.node.childItems && page.node.childItems.edges.length > 0) && (
                                                            <img src={ChevronDown} onClick={(e) => openMobileMenu(e, i)} style={{ width: "13px", paddingLeft: "5px" }} />
                                                        )}
                                                    </Link>

                                                    {(page.node.childItems && page.node.childItems.edges.length > 0) && (
                                                        <ul className="dropdown-menu" style={mobileActive === i ? { position: "relative", top: "0" } : { position: "absolute" }}>
                                                            {page.node.childItems.edges.map((inPage, j) => (
                                                                <li className="nav-item" key={`nav-link-${j}`}>
                                                                    <Link
                                                                        to={`${inPage.node.url.includes("admin.w3vitals.com") ? inPage.node.url.replace("https://admin.w3vitals.com", "") : `/${inPage.node.url.replace("http://", "")}/`}`}
                                                                        activeClassName="active"
                                                                        className="nav-link"
                                                                    >
                                                                        {inPage.node.label}
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </li>
                                            )
                                        }
                                    })}
                                    <li className="d-flex align-items-center nav-item">
                                        <select className='lang-sw-sel' value={selectedOption} onChange={(e) => changeLang(e.target.value)} >
                                            <option value={"EL"}>EL</option>
                                            <option value={"EN"}>EN</option>
                                            <option value={"FR"} >FR</option>
                                        </select>
                                       
                                    </li>

                                </ul>
                                <div className="others-option d-flex align-items-center">
                                    {/* <div className="option-item">
                                        <form className="search-box">
                                            <input type="text" className="input-search" placeholder="Search for anything" />
                                            <button type="submit">
                                                <i className="flaticon-loupe"></i>
                                            </button>
                                        </form>
                                    </div> */}

                                    <div className="option-item">
                                        {
                                            props.lang === "EN"
                                                ?
                                                <Link
                                                    to="/en/contact/"
                                                    activeClassName="active"
                                                    className="default-btn"
                                                    style={{ paddingLeft: "25px" }}
                                                >
                                                    Get Started <span></span>
                                                </Link>
                                                :
                                                (
                                                    props.lang === "FR"
                                                        ?
                                                        <Link
                                                            to="/fr/contact/"
                                                            activeClassName="active"
                                                            className="default-btn"
                                                            style={{ paddingLeft: "25px" }}
                                                        >
                                                            Nous contacter <span></span>
                                                        </Link>
                                                        :
                                                        <Link
                                                            to="/epikoinonia/"
                                                            activeClassName="active"
                                                            className="default-btn"
                                                            style={{ paddingLeft: "25px" }}
                                                        >
                                                            Ξεκινήστε <span></span>
                                                        </Link>
                                                )


                                        }
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Navbar;
