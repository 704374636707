import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import CareerDetailsContent from '../components/Career/CareerDetailsContent'

const CaseStudyTemplate = (props) => {
    let data = props.data.wpgraphql;

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://w3vitals.com${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://w3vitals.com${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }
    
    return (
        <Layout metaData={constructMetaData(data.job, props.pageContext.currentPage, props.pageContext.language)}>
            <Navbar header={props.pageContext.headerMenu}  lang={props.pageContext.language}  />
            <PageBanner
                pageTitle={data.job.title} 
                homePageText={props.pageContext.language ==="EN" ? "Home" : (props.pageContext.language ==="FR" ? "Accueil" : "Αρχική")}
                homePageUrl={props.pageContext.language ==="EN" ? "/en/" : (props.pageContext.language ==="FR" ? "/fr/" : "/")}
                prevPageText="Καριέρα"
                prevPageUrl="/career/"
                activePageText={data.job.title} 
            />

            <CareerDetailsContent
                job={data.job}
            />
            {/* <CaseStudiesDetailsContent 
                caseStudy={data.caseStudy}
            /> */}
            <Footer footer={props.pageContext.footerMenu} extra={data.footerTemplate.footerTemplateAcf}/>
        </Layout>
    );
}

export default CaseStudyTemplate

export const pageQuery = graphql`
    query GET_CAREER_JOB($id: ID!, $footerTemplateUri: ID!) {
        wpgraphql {
            job(id: $id){
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                }
                title
                content
                uri
            }
            footerTemplate: template(id: $footerTemplateUri, idType: SLUG) {
                footerTemplateAcf{
                    addressTitle
                    addressContent
                    content
                }
            }
        }
    }
`
